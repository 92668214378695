<template>
  <div id="email-verification" class="box container">
    <p class="has-text-success" v-if="success">{{ $t('account.email_verification_success')}}</p>
    <p class="has-text-danger" v-if="errorMessage">{{ $t(errorMessage) }}</p>
  </div>
</template>

<script>

export default{
  name: 'email-verification',
  data(){
    return {
      errorMessage: '',
      success: false,
    };
  },
  mounted: function(){
    console.log(this.$route.query.token);
    this.post('/email_verification', { token: this.$route.params.token }).then(() => {
      this.success = true;
      this.$store.getters.user.email_verified = true;
      setTimeout(() => { window.location.replace(process.env.VUE_APP_URL+'/dashboard'); }, 3000);
    }).catch(err => {
      console.error(err);
      this.errorMessage = err;
      setTimeout(() => { window.location.replace(process.env.VUE_APP_URL+'/dashboard'); }, 5000);
    });
  },
}
</script>

<style lang="scss" scoped>
</style>
